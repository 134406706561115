import { render, staticRenderFns } from "./AddWard.vue?vue&type=template&id=529b7b49&scoped=true"
import script from "./AddWard.vue?vue&type=script&lang=js"
export * from "./AddWard.vue?vue&type=script&lang=js"
import style0 from "./AddWard.vue?vue&type=style&index=0&id=529b7b49&lang=css"
import style1 from "./AddWard.vue?vue&type=style&index=1&id=529b7b49&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "529b7b49",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jissjohnson/code/Zenrion/CareKraft/zenrion-erp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('529b7b49')) {
      api.createRecord('529b7b49', component.options)
    } else {
      api.reload('529b7b49', component.options)
    }
    module.hot.accept("./AddWard.vue?vue&type=template&id=529b7b49&scoped=true", function () {
      api.rerender('529b7b49', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/AddWard.vue"
export default component.exports